<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2>Mark As Settled</h2>
      </div>
      <div
        class="dialog-body"
      >

        <div
          class="transparent">
          <el-form
            ref="form"
            :model="formData"
            :rules="rules"
          >
            <div
              v-if="isMonth"
              class="form-item">
              <span>Month</span>
              <el-form-item prop="month">
                <v-date-picker
                  v-model="formData.month"
                  :format="'yyyy-MM'"
                  type="month"
                ></v-date-picker>
              </el-form-item>
            </div>

            <div class="form-item">
              <span>Settle Date</span>
              <el-form-item
                prop="settle_date"
                :rules="rules.select">
                <v-date-picker
                  v-model="formData.settle_date"
                  type="date"
                ></v-date-picker>
              </el-form-item>
            </div>

            <div class="form-item">
              <span>Remark</span>
              <el-form-item prop="remark">
                <v-input
                  v-model="formData.remark"
                  type="textarea"
                  :rows="4"></v-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item prop="type">
                <v-radio-group
                  v-model="formData.type"
                  :list="refundOptions"
                ></v-radio-group>
              </el-form-item>
            </div>
            <div class="form-item">
              <span>Amount</span>
              <el-form-item
                prop="amount"
                :rules="rules.input">
                <v-input
                  v-model="formData.amount"
                  key-non-chinese
                  money
                  prepend="HKD"
                ></v-input>
              </el-form-item>
            </div>
            <div
              v-if="formData.type!=3"
              class="form-item">
              <span>Document</span>
              <el-form-item prop="covernote_document_file_id">
                <file-container
                  :edit="true"
                  :fileData="{
                    url: formData.statement_file_url,
                    client_name: formData.statement_file_client_name
                  }"
                  :otherData="{
                    idKey: 'statement_file_id',
                    urlKey: 'statement_file_url',
                    client_name:'statement_file_client_name'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              <!--              <v-upload v-model="formData.covernote_document_file_id"></v-upload>-->
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="dialog = false">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">Mark As Settled
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {endorsementQuotationMotor} from '@api/endorsement'
import {saveQuotationMotor} from '@api/quotation'
export default {
  name: 'hirePurchaseDialog',
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isMonth:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      dialog:false,
      isSent:false,
      formData:{
        settle_date:'',
        remark:'',
        type:3,
        // month:'',
        statement_file_id:'',
        statement_file_client_name:'',
        statement_file_url:'',
        amount:''

      }

    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
  },
  watch:{
    form:{
      immediate:true,
      handler(val){
        let {statement,payment_type,settle_amount} = val||{}
        let {client_name,file_id,url,} =statement||{}
        Object.keys(this.formData).some(key=>{
          if(key in val)
            this.formData[key] = val[key]
        })
        this.formData.statement_file_id = file_id
        this.formData.statement_file_client_name = client_name
        this.formData.statement_file_url = url
        this.formData.type = payment_type||3
        this.formData.amount = settle_amount||''
      }
    }
  },
  created() {

  },
  methods: {
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    showReferDialog(){
      this.dialog = !this.dialog
    },
    handlerSave(){
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('handlerSettle',this.formData)
          this.dialog =false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 24px;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #718096;
        }
      }
    }

    .transparent{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
