import { render, staticRenderFns } from "./transcation.vue?vue&type=template&id=51882516&scoped=true&"
import script from "./transcation.vue?vue&type=script&lang=js&"
export * from "./transcation.vue?vue&type=script&lang=js&"
import style0 from "./transcation.vue?vue&type=style&index=0&id=51882516&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51882516",
  null
  
)

export default component.exports