<template>
  <section
    v-if="is_effective"
    class="transaction-content">
    <el-row
      :gutter="20">
      <v-row-col>
        <div class="content">
          <div class="content-title flex flex-justify">
            <h2>Insurer - {{isRefund?'Refund':'Transaction'}}</h2>
            <div>
              <v-button
                class="button-theme"
                @click="handlerInsurer(form.company_id)">Transactions List</v-button>
            </div>
          </div>
          <div class="font-content">
            <p>{{showHkDate(insurer_transaction.settle_date)}}</p>
            <p>
              {{insurer_transaction.remark}}
            </p>
            <span
              class="link hand"
              @click="openFile(insurer_transaction.statement)">
              {{insurer_client_name }}
            </span>
          </div>
          <div>
          </div>
        </div>
      </v-row-col>
      <v-row-col  v-if="form.referral_id<=0&&isRefund">
        <div
          class="content">
          <div class="content-title flex flex-justify">
            <h2>Client -  Refund</h2>
            <div>
              <v-button
                class="button-theme"
                @click="handlerMark(form.referral_id)">{{client_transaction?'Update':'Settle'}}</v-button>
            </div>
          </div>
          <div class="font-content">
            <p>
              <span class="mg-r-8">{{getOptionName('refundOptions',client_transaction.payment_type)}} HK{{client_transaction.settle_amount|formatMoney(2,client_transaction)}}</span>
              <span>{{showHkDate(client_transaction.settle_date)}}</span>
            </p>
            <p>
              {{client_transaction.remark}}
            </p>
            <span
              class="link hand"
              @click="openFile(client_transaction.statement)">
              {{client_client_name}}
            </span>
          </div>
          <div>
          </div>
        </div>
      </v-row-col>
      <v-row-col>
        <div
          v-if="form.referral_id>0"
          class="content">
          <div class="content-title flex flex-justify">
            <h2>Referrer -  {{isRefund?'Refund':'Transaction'}}</h2>
            <div>
              <v-button
                class="button-theme"
                @click="handlerReferrer(form.referral_id)">Transactions List</v-button>
            </div>
          </div>
          <div class="font-content">
            <p>{{showHkDate(referrer_transaction.settle_date)}}</p>
            <p>
              {{referrer_transaction.remark}}
            </p>
            <span
              class="link hand"
              @click="openFile(referrer_transaction.statement)">
              {{referrer_client_name}}
            </span>
          </div>
          <div>
          </div>
        </div>
      </v-row-col>
    </el-row>
    <mark-dialog
      ref="mark"
      :form="client_transaction"
      :isMonth="isMonth"
      @handlerSettle="handlerSettle"></mark-dialog>
  </section>
</template>

<script>
import MixinOption from '@/mixins/options'
import {clientSettle} from '@api/order'
import markDialog from '../../components/dialog/markDialog'
import MixinFormat from '@/mixins/format'
export default {
  name: 'transcation',
  components:{
    markDialog
  },
  mixins: [MixinOption,MixinFormat],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isMonth:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    is_effective(){
      let {order_status} = this.form||{}
      return order_status>10
    },
    referrer_transaction(){
      let {referrer_transaction = {}} = this.form
      return  referrer_transaction
    },
    referrer_client_name(){
      let {referrer_transaction = {}} = this.form
      let {statement={}} = referrer_transaction||{}
      return statement&&statement.client_name||''
    },
    client_transaction(){
      let {client_transaction = {}} = this.form
      console.log('client_transaction',client_transaction)
      return  client_transaction
    },
    insurer_client_name(){
      let {insurer_transaction = {}} = this.form
      let {statement={}} = insurer_transaction||{}
      return statement&&statement.client_name||''
    },
    client_client_name(){
      let {client_transaction = {}} = this.form
      let {statement={}} = client_transaction||{}
      return statement&&statement.client_name||''
    },
    insurer_transaction(){
      let {insurer_transaction} = this.form
      return  insurer_transaction||{}
    },
    isCancel(){
      return this.form.policy_type===204
    },
    isRefund(){
      let {premium={}} = this.form
      let {is_refund_order } =premium||{}
      return is_refund_order
    },
    amount_received(){
      let {premium={}} = this.form
      let {amount_received = 0} = premium||{}
      return amount_received!=0
    }
  },
  methods:{
    handlerReferrer(id){
      this.$router.push('/member/referral/statement/'+id)
    },
    handlerInsurer(id){
      this.$router.push('/member/insurer/statement/'+id)
    },
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    handlerMark(){
      this.$refs.mark.showReferDialog()
    },
    handlerSettle(data) {
      let order_no = this.$route.query.order_no
      let params = {...data, order_no}
      this.network().setSettle(params)
    },
    network(){
      return{
        setSettle: async (params) => {
          const {data} = await clientSettle(params)
          this.$emit('isRefresh')
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .transaction-content{
    margin-bottom: 20px;
    margin-top: 20px;
    .content{
      padding: 11px 20px 20px;
      background: #FFFFFF;
      border: 1px solid #CBD5E0;
      h2{
        font-weight: bold;
        font-size: 14px;
        color: #2D3748;
      }
    }
    .font-content{
      margin-top: 11px;
      font-size: 14px;
      color: #2D3748;
      p:nth-child(1){
        margin-bottom: 10px;
      }
      .link{
        display: block;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
</style>
